import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import Switch from 'components/widgets/Switch';
import {useGetCurrentOperator, useChatNotification} from 'hooks';
import {t, h, addRefFocus} from 'utils';
import {useCurrentUserPlan} from 'hooks';

export default function NavMenu() {
  const {userCurrentPlan} = useCurrentUserPlan();
  const links = [
    {title: t(`sidebar.chats`), to: '/chats', image: require('styles/images/chat.svg')},
    {title: t(`sidebar.campaigns`), to: '/campaigns', image: require('styles/images/two-files-folders.svg')},
    {title: t(`sidebar.leads`), to: '/leads', image: require('styles/images/team.svg')},
    {title: h(`sidebar.liveVisitors`), to: '/live-visitors', image: require('styles/images/eye.svg')},
    {title: t(`sidebar.meetings`), to: '/meetings-settings', image: require('styles/images/organization.svg')},
    {title: t(`sidebar.analytics`), to: '/analytics', image: require('styles/images/analytics.svg')},
    {title: t(`sidebar.settings`), to: '/settings', image: require('styles/images/gear.svg')},
  ];
  const [isOverlayNavbar, toggleOverlayNavbar] = useState(false);
  const [isPopupAvaibilityShow, showPopupAvaibility] = useState(false);
  const [currentOperator] = useGetCurrentOperator();

  return (
    <div data-collapse="small" data-animation="default" data-duration="400" className="main-navbar w-nav">
      <div className="nav-container">
        <a href="#" className="brand w-nav-brand">
          <img src={require('styles/images/logo-1.svg')} alt="" className="logo-icon"/>
        </a>
        <nav role="navigation" className="nav-menu w-nav-menu">
          <div className="nav-links-w">
            <div className="nav-links-div">
              {links.map((link, i) => {
                if (!userCurrentPlan.live_visitors && link.to === '/live-visitors') {
                  return null;
                }
                if (!userCurrentPlan.meetings && link.to === '/meetings-settings') {
                  return null;
                }
                return (
                  <NavLink key={i} to={link.to} className="nav-link w-inline-block" activeClassName="w--current">
                    <img src={link.image} alt="" className="nav-link-icon"/>
                    <div className="nav-link-name link-animation">{link.title}</div>
                  </NavLink>
                );
              })}
            </div>
            <div className="nav-link w-inline-block"
                 onClick={() => showPopupAvaibility(!isPopupAvaibilityShow)}>
              <img src={require('styles/images/flash.svg')} alt=""
                   className="nav-link-icon"/>
              {currentOperator && (
                <div className="ava-block w-clearfix">
                  {currentOperator.is_online ? (
                    <div className="ava-orange"></div>
                  ) : (
                    <div className="ava-green"></div>
                  )}
                </div>
              )}
              <div className="nav-link-name link-animation">{t('sidebar.avaibility')}</div>
            </div>
          </div>
        </nav>
        <div className="menu-button w-nav-button" onClick={() => toggleOverlayNavbar(!isOverlayNavbar)}>
          <div className="w-icon-nav-menu"></div>
        </div>
        <div className={`w-nav-overlay w--nav-overlay-open ${isOverlayNavbar ? 'nav-menu-overlay-open' : 'nav-menu-overlay-close'}`}>
          <nav role="navigation"
               className={`nav-menu w-nav-menu w--nav-menu-open ${isOverlayNavbar ? 'nav-menu-open' : 'nav-menu-close'}`}>
            <div className="nav-links-w" onClick={() => toggleOverlayNavbar(false)}>
              <div className="nav-links-div">
                {links.map((link, i) => (
                  <NavLink key={i} to={link.to} className="nav-link w-inline-block" activeClassName="w--current">
                    <img src={link.image} alt="" className="nav-link-icon"/>
                    <div className="nav-link-name link-animation">{link.title}</div>
                  </NavLink>
                ))}
              </div>
              <div className="nav-link w-inline-block"
                   onClick={() => showPopupAvaibility(!isPopupAvaibilityShow)}>
                <img src={require('styles/images/flash.svg')} alt=""
                     onClick={() => showPopupAvaibility(!isPopupAvaibilityShow)}
                     className="nav-link-icon"/>
                <div className="nav-link-name link-animation">{t('sidebar.avaibility')}</div>
                {currentOperator && (
                  <div className="ava-block w-clearfix">
                    {currentOperator.is_online ? (
                      <div className="ava-orange"></div>
                    ) : (
                      <div className="ava-green"></div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>
      {isPopupAvaibilityShow && (
        <PopupAvaibility isOpen={isPopupAvaibilityShow} onClose={() => showPopupAvaibility(false)}/>
      )}
    </div>
  );
}

const PopupAvaibility = (props) => {
  const {isOpen, onClose} = props;
  const [currentOperator, updateCurrentOperator] = useGetCurrentOperator();
  const {turnOffNotification, toggleNotification} = useChatNotification();
  const [isOnline, toggleOnline] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    if (currentOperator) {
      toggleOnline(currentOperator.is_online);
    }
  }, [currentOperator]);

  useEffect(() => {
    if (isOpen && ref && ref.current) {
      addRefFocus(ref.current)
    }
  }, [isOpen, ref.current]);

  useEffect(() => {
    if (currentOperator && (isOnline !== null) && isOnline !== currentOperator.is_online) {
      updateCurrentOperator(currentOperator.id, {is_online: isOnline});
    }
  }, [isOnline]);

  if (!currentOperator || !currentOperator.id) {
    return null;
  }

  return (
    <div className="avaibility-block2" ref={ref} tabIndex={-1}
         onBlur={onClose}>
      <div className="popup-container-block2">
        <div className="group-w-header ava mb30">
          <div>
            <div className="visitor-info-w">
              <div className="chat-avatar-2">
                {currentOperator.user.avatar.url ? (
                  <img className="chat-avatar-icon-img" src={currentOperator.user.avatar.url}/>
                ) : (
                  <div>S</div>
                )}
                </div>
              <div className="ava-title">{currentOperator.user.name || currentOperator.name}</div></div>
          </div>
          <div onClick={() => toggleNotification(!turnOffNotification)}>
            <img src={!turnOffNotification ? require('styles/images/bell-active.svg')
              : require('styles/images/bell-disactive.svg')} alt="" className="image-2 notification-alarm"/>
          </div>
        </div>
        <div className="group-w-header ava">
          {isOnline ? (
            <div>
              <div className="ava-title">Online</div>
              <div>Accepting new chats</div>
            </div>
          ) : (
            <div>
              <div className="ava-title">Offline</div>
              <div>Not accepting new chats</div>
            </div>
          )}
          <Switch isOn={isOnline} onClick={() => toggleOnline(!isOnline)}/>
        </div>
      </div>
    </div>
  );
};