import React, {useEffect, useState} from 'react';
import 'preact/debug';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import {StripeProvider} from 'react-stripe-elements';
import Alert from 'react-s-alert';

import settings from 'settings';
import './App.css';
import {useWidgets, useChatSockets, useCurrentUser, useOperators,
  useChatNotification, useCampaigns, useCurrentUserPlan} from 'hooks';
import NavMenu from 'components/NavMenu';
import NotFound from 'components/NotFound';
import Analytics from 'components/views/Analytics';
import BillingContainer from 'components/views/Billing/BillingContainer';
import Campaigns from 'components/views/Campaigns';
import ChooseWebChatCampaign from 'components/views/ChooseWebChatCampaign';
import CreateWebChatCampaign from 'components/views/CreateWebChatCampaign';
import CreateFacebookMessengerCampaign from 'components/views/CreateFacebookMessengerCampaign';
import CampaignSettings from "components/views/CampaignSettings";
import Chats from 'components/views/Chats';
import Leads from 'components/views/Leads';
import LiveVisitors from 'components/views/LiveVisitors';
import Settings from 'components/views/Settings';
import TariffPlans from 'components/views/TariffPlans';
import UserAccount from 'components/views/UserAccount';
import Loader from 'components/widgets/Loader';
import FadeLoader from 'components/widgets/FadeLoader';
import IntlWrapper from 'components/widgets/IntlWrapper';
import {main} from 'translate';
import CreateLandBotCampaign from "../views/CreateLandBotCampaign";
import Meetings from "../views/Meetings";
import ConnectCalendar from "../views/Meetings/ConnectCalendar";
import StripeFactory from "../../api/StripeFactory";
import NewSubscriptionContainer from "../views/NewSubscription/NewSubscriptionContainer";


function App () {
  const {widgetsById, isFetching, isWidgetFetching, getWidgets} = useWidgets();
  // need for init current user
  const {currentUser, getCurrentUser} = useCurrentUser();
  const {userCurrentPlan, getUserCurrentPlan} = useCurrentUserPlan();
  const {getCampaigns} = useCampaigns();
  const {getOperators} = useOperators();
  const {currentWidgetId} = useWidgets();

  const [stripe, setStripe] = useState(null);

  const {operatorStartTyping, operatorEndTyping, channel} = useChatSockets();
  useChatNotification();

  const locale = 'en';

  useEffect(() => {
    getWidgets();
    getCurrentUser();
      // .then((currentUserId) => {
      //   if (currentUserId) {
      //     getUserCurrentPlan({widget_id: currentWidgetId});
      //   }
      // });
  }, []);

  useEffect(() => {
    const setStripeState = () => {
      const stripe = StripeFactory.getInstance(settings.STRIPE_API_KEY, {
        locale: locale,
      });
      setStripe(stripe);
    };

    if (window.Stripe) {
      console.log('Stripe loaded first');
      setStripeState();
    } else {
      console.log('App loaded first');
      window.initializeReactStripeElements = setStripeState();
    }
  }, []);

  useEffect(() => {
    if (currentWidgetId && channel) {
      getOperators();
      getCampaigns({
        send_statistics: true
      });
      getUserCurrentPlan({widget_id: currentWidgetId});
    }
  }, [currentWidgetId, channel]);

  if (!widgetsById || !userCurrentPlan.id || !currentUser) {
    return (<Loader/>);
  }


  return (
    <IntlWrapper locale={locale} messages={[main]}>
      <Router>
        <StripeProvider stripe={stripe}>
          <div className="main-wrapper flex-unset">
            <NavMenu/>
            <div className="page-container full-background">
              <Switch>
                <Route exact path="/analytics" component={Analytics}/>
                {userCurrentPlan.meetings && (<Route exact path="/meetings-settings" component={Meetings}/>)}
                <Route exact path="/upgrade" component={TariffPlans}/>
                <Route exact path="/billing" component={BillingContainer}/>
                <Route exact path="/new-subscription" component={NewSubscriptionContainer}/>
                <Route exact path="/campaigns" component={Campaigns}/>
                <Route exact path="/campaigns/:campaignId" component={CampaignSettings} />
                <Route exact path="/campaigns/create/web-chat" component={ChooseWebChatCampaign}/>
                <Route exact path="/campaigns/create/web-chat/:type" component={CreateWebChatCampaign}/>
                <Route exact path="/campaigns/create/facebook-messenger" component={CreateFacebookMessengerCampaign}/>
                <Route exact path="/campaigns/create/landbot" component={CreateLandBotCampaign}/>
                <Route path={["/chats/:id", "/chats"]} render={props =>
                  <Chats {...props} operatorStartTyping={operatorStartTyping} operatorEndTyping={operatorEndTyping}/>}/>
                <Route exact path="/leads" component={Leads}/>
                {userCurrentPlan.live_visitors && (<Route exact path="/live-visitors" component={LiveVisitors}/>)}
                <Route exact path="/settings" component={Settings}/>
                <Route exact path="/account" component={UserAccount}/>
                <Route exact path="/" render={() => <Redirect to="/chats" />} />
                <Route exact component={NotFound}/>
              </Switch>
            </div>
            <Alert stack={{limit: 3, position: 'top-right', effect: 'slide', timeout: 3000}} />
          </div>
        </StripeProvider>
      </Router>
    </IntlWrapper>
  );
}

export default App;
