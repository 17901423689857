import React from "react";
import { t } from "utils";
import update from "immutability-helper";
import DropdownBlock from "components/widgets/DropdownBlock";
import CustomSelect from "components/widgets/CustomSelect";
import ButtonImageLoader from "components/widgets/ButtonImageLoader";

export default function SeoSettings(props) {
  const { campaign, setCampaign } = props;

  const _t = (value, values = {}, children = null) => {
    return t(`landbot.create.displaySettings.seo.${value}`, values, children);
  };

  const settingBlocks = [
    {
      value: "Seo",
      label: _t("seo.header"),
      content: <Seo campaign={campaign} setCampaign={setCampaign} _t={_t} />,
    },
    {
      value: "Tracking",
      label: _t("tracking.header"),
      content: (
        <Tracking campaign={campaign} setCampaign={setCampaign} _t={_t} />
      ),
    },
  ];

  return (
    <div className="w-tab-pane w--tab-active">
      <div className="pane-content-w">
        <div className="div-block-5">
          <div className="content-white-block-2 grow-w">
            {settingBlocks.map((block, i) => (
              <DropdownBlock
                title={block.label}
                content={block.content}
                additionalClass={i === 0 ? "no-margin-top" : ""}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const Seo = (props) => {
  const { campaign, setCampaign, _t } = props;
  const settings = campaign.display_settings.seo;

  return (
    <>
      <div className="show-group-content-2-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-6">
              <div className="settings-item-label add-margin">
                {_t("seo.meta.title")}:
              </div>
              {_t("seo.meta.placeholder", {}, (msg) => (
                <input
                  type="text"
                  value={settings.meta_title}
                  onChange={(e) =>
                    setCampaign(
                      update(campaign, {
                        display_settings: {
                          seo: { meta_title: { $set: e.target.value } },
                        },
                      })
                    )
                  }
                  className="node-settings-input w-input"
                  maxLength="256"
                  placeholder={msg}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="show-group-content-2-2">
        <div className="show-group-div">
          <div className="flex-row ">
            <div className="col-6">
              <div className="settings-item-label add-margin">
                {_t("seo.meta.image.label")}:
              </div>
              <div className="flex-row">
                <ButtonImageLoader
                  text={_t("seo.meta.image.add")}
                  onChange={(file) =>{                                   
                    setCampaign(
                      update(campaign, {
                        display_settings: {
                          seo: { meta_image: { $set: file.file } },
                        },
                      })
                    )}
                  }
                  preview={settings.meta_image.url ||  settings.meta_image.preview}
                  img=<img
                    src={require("styles/images/upload-cloud.svg")}
                    className="image-6"
                    alt=""
                  />
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="show-group-content-2-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-6">
              <div className="settings-item-label add-margin">
                {_t("seo.icon.label")}:
              </div>
              <div className="flex-row">
                <ButtonImageLoader
                  text={_t("seo.icon.label")}
                  onChange={(file) =>
                    setCampaign(
                      update(campaign, {
                        display_settings: {
                          seo: { meta_favicon: { $set: file.file } },
                        },
                      })
                    )
                  }
                  preview={settings.meta_favicon.url ||  settings.meta_favicon.preview}
                  img=<img
                    src={require("styles/images/upload-cloud.svg")}
                    className="image-6"
                    alt=""
                  />
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="show-group-content-2-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-6">
              <div className="settings-item-label add-margin">
                {_t("seo.site.description.label")}:
              </div>
              {_t("seo.site.description.placeholder", {}, (msg) => (
                <textarea
                  value={settings.site_description}
                  onChange={(e) =>
                    setCampaign(
                      update(campaign, {
                        display_settings: {
                          seo: { site_description: { $set: e.target.value } },
                        },
                      })
                    )
                  }
                  className="node-settings-input-2 textarea w-input"
                  maxLength="256"
                  placeholder={msg}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Tracking = (props) => {
  const { campaign, setCampaign, _t } = props;
  const settings = campaign.display_settings.seo;

  const AnalyticSendTypes = {
    "Send no events to GA": { value: "Send no events to GA" },
    "Send all user responses as events to GA": {
      value: "Send all user responses as events to GA",
    },
  };

  return (
    <>
      <div className="show-group-content-2-2">
        <div className="show-group-div">
          <div className="show-group-content-2-2 no-border-top">
            <div className="show-group-div">
              <div className="group-row">
                <div className="col-6">
                  <div className="settings-item-label add-margin">
                    {_t("tracking.analytics.label")}:
                  </div>
                  {_t("tracking.analytics.placeholder", {}, (msg) => (
                    <input
                      type="text"
                      value={settings.google_analytics.code}
                      onChange={(e) =>
                        setCampaign(
                          update(campaign, {
                            display_settings: {
                              seo: {
                                google_analytics: {
                                  code: { $set: e.target.value },
                                },
                              },
                            },
                          })
                        )
                      }
                      className="node-settings-input-2 w-input"
                      maxLength="256"
                      placeholder={msg}
                    />
                  ))}
                </div>
              </div>
              <div className="group-row">
                <div className="col-6">
                  <CustomSelect
                    options={Object.values(AnalyticSendTypes)}
                    defaultValue={Object.values(AnalyticSendTypes)[0]}
                    selectClassName={`settings-select w-select`}
                    getOptionLabel={(option) => option.value}
                    onChange={(type) =>
                      setCampaign(
                        update(campaign, {
                          display_settings: {
                            seo: {
                              google_analytics: {
                                send_type: { $set: type.value },
                              },
                            },
                          },
                        })
                      )
                    }
                    value={
                      AnalyticSendTypes[settings.google_analytics.send_type]
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="show-group-content-2-2">
        <div className="show-group-div">
          <div className="show-group-content-2-2 no-border-top">
            <div className="show-group-div">
              <div className="col-6">
                <div className="settings-item-label add-margin">
                  {_t("tracking.facebook.label")}:
                </div>
                {_t("tracking.facebook.placeholder", {}, (msg) => (
                  <input
                    type="text"
                    value={settings.facebook_pixel_id}
                    onChange={(e) =>
                      setCampaign(
                        update(campaign, {
                          display_settings: {
                            seo: {
                              facebook_pixel_id: { $set: e.target.value },
                            },
                          },
                        })
                      )
                    }
                    className="node-settings-input-2 w-input"
                    maxLength="256"
                    placeholder={msg}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="show-group-content-2-2">
        <div className="group-title">
          <strong>{_t("tracking.google.title")}</strong>
        </div>
      </div>

      <div className="group-row">
        <div className="col-6">
          <div className="settings-item-label add-margin">
            {_t("tracking.google.remarketing.label")}:
          </div>
          {_t("tracking.google.remarketing.placeholder", {}, (msg) => (
            <input
              type="text"
              value={settings.google_ads.conversion_id}
              onChange={(e) =>
                setCampaign(
                  update(campaign, {
                    display_settings: {
                      seo: {
                        google_ads: { conversion_id: { $set: e.target.value } },
                      },
                    },
                  })
                )
              }
              className="node-settings-input-2 w-input"
              maxLength="256"
              placeholder={msg}
            />
          ))}
        </div>
      </div>

      <div className="group-row">
        <div className="col-6">
          <div className="settings-item-label add-margin">
            {_t("tracking.google.remarketing.label")}:
          </div>
          {_t("tracking.google.remarketing.placeholder", {}, (msg) => (
            <input
              type="text"
              value={settings.google_ads.conversion_id}
              onChange={(e) =>
                setCampaign(
                  update(campaign, {
                    display_settings: {
                      seo: {
                        google_ads: { conversion_id: { $set: e.target.value } },
                      },
                    },
                  })
                )
              }
              className="node-settings-input-2 w-input"
              maxLength="256"
              placeholder={msg}
            />
          ))}
        </div>
      </div>

      <div className="group-row">
        <div className="col-6">
          <div className="settings-item-label add-margin">
            {_t("tracking.google.tagManager.label")}:
          </div>
          {_t("tracking.google.tagManager.placeholder", {}, (msg) => (
            <input
              type="text"
              value={settings.google_ads.tag_manager_container_id}
              onChange={(e) =>
                setCampaign(
                  update(campaign, {
                    display_settings: {
                      seo: {
                        google_ads: {
                          tag_manager_container_id: { $set: e.target.value },
                        },
                      },
                    },
                  })
                )
              }
              className="node-settings-input-2 w-input"
              maxLength="256"
              placeholder={msg}
            />
          ))}
        </div>
      </div>
    </>
  );
};
