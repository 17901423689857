import React, {Fragment, useState, useEffect} from 'react';
import ReactModal from 'react-modal';

import Header from 'components/Header';
import StepChain from '../StepChain';
import {t} from 'utils';
import update from "immutability-helper";
import DropdownBlock from "components/widgets/DropdownBlock";
import ColorPicker from "components/widgets/ColorPicker";
import Switch from "components/widgets/Switch";
import GradientPicker from "components/widgets/GradientPicker";
import CustomSelect from "components/widgets/CustomSelect";
import Workspace from "components/widgets/Workspace";
import ButtonImageLoader from "components/widgets/ButtonImageLoader";
import {useVideos, useWidgets, useCurrentUserPlan, useCampaigns} from 'hooks';
import {BACKGROUND_TYPES} from 'constants.js';
import settings from 'settings';
import SubmitButton from "../../../widgets/SubmitButton";


export default function DesignSettings(props) {
  const queryArgPrefix = settings.SERVICE_BRAND_NAME.toLowerCase();
  const {isCreate, type, currentStep, setCurrentStep, campaign, setCampaign, onSave} = props;
  const {currentWidget} = useWidgets();
  const {isCreating, isUpdating} = useCampaigns();

  const _t = (value, values = {}, children = null) => {
    return t(`landbot.create.designSettings.${value}`, values, children);
  };

  const settingBlocks = [
    {value: 'Main settings', label: _t('main.tab'),
      content: <MainSettings campaign={campaign} setCampaign={setCampaign} _t={_t}/>},
    {value: 'Design settings', label: _t('design.tab'),
      content: <Design campaign={campaign} setCampaign={setCampaign} _t={_t}/>},
    {value: 'Font settings', label: _t('font.tab'),
      content: <FontSettings campaign={campaign} setCampaign={setCampaign} _t={_t}/>},
  ];


  //console.log(campaign.html_url)
  return (
    <Fragment>
      <Header title={_t(`header${campaign && campaign.id ? '.edit' : ''}`)}>
          <StepChain type={type} currentStep={currentStep} campaign={campaign}
                     setCurrentStep={setCurrentStep} additionalClass="no-bottom-indent"/>
      </Header>
      <div className="editor-form-block w-form overflow-hidden">
        <form id="email-form" name="email-form" data-name="Email Form" className="editor-form">
          <div className="editor-cont-wrap">
            <div className="editor-sidebar-wrap">
              <div className="group-content-w no-indent no-top-bord">
                <div className="sidebar-tab-cont">
                    {settingBlocks.map((block,i) =>
                      <DropdownBlock title={block.label}
                                     content={block.content}
                                     additionalClass={i === 0 ? 'no-margin-top' : ''}/>
                    )}
                </div>
              </div>
            </div>
            <div className="editor-workspace-wrap landbot-preview">
              <div className="editor-control-w">
                {isCreate ? (
                  <div onClick={() => setCurrentStep(currentStep + 1)} className="button right-indent w-button">
                    Go to display settings
                  </div>
                ) : (
                  <SubmitButton simpleButton={true} additionalClass={'right-indent w-button'}
                                onClick={() => onSave(campaign.enabled, false)}
                                isSending={(isCreating || isUpdating)}
                                onLoadEnd={() => {window.location = '/campaigns'}}
                                text={t(`button.save`)}/>
                )}

              </div>
              <Workspace
                widgetSettings={{...currentWidget, campaignsById: {[campaign.id] : campaign}}}
                //url={window.location.hostname.includes('localhost') ? '/landbot.html?chatbots_landbot_mode=' : `${campaign.html_url}?chatbots_landbot_mode=`}
                url={`/landbot.html?${queryArgPrefix}_landbot_mode=`}
                widgetType='landbot'
                modes={[
                  {value: 'ww9ySxE9BY', label: 'Landbot'},
                  //{value: 'ww9ySxE8BY', label: 'Landbot'}
                ]}
              />
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

const MainSettings = (props) => {
  const {campaign, setCampaign, _t} = props;
  const {userCurrentPlan} = useCurrentUserPlan();
  const settings = campaign.design.main_settings;

  return (
    <>
      <div className="show-group-content-2 no-top-m">
        <div className="show-group-div">
          <div className="settings-item-label add-margin">{_t('logo')}:</div>
          <div className="div-block-11">
            <ButtonImageLoader text={_t('uploadImage')}
                               onChange={file => {
                                 setCampaign(update(campaign,
                                   {design: {main_settings: {logo: {$set: file.file}, logo_url: {$set: ''}}}}))
                               }}/>
          </div>
          <div>
            <div className="page-subheader add-margin">{_t('enterUrl')}</div>
            {_t('enterUrl.placeholder', {},
              msg => <input type="text" value={settings.logo_url}
                            onChange={e => setCampaign(update(campaign,
                              {design: {main_settings: {logo_url: {$set: e.target.value}, logo: {$set: null}}}}))}
                            className="node-settings-input w-input" maxLength="256" placeholder={msg}/>)}
          </div>
        </div>
      </div>

      <div className="show-group-content-2 no-top-m">
        <div className="show-group-div">
          <div>
            <div className="settings-item-label add-margin">{_t('title.label')}:</div>
            <input value={settings.title}
                   type="text" className="node-settings-input w-input" maxLength="256"
                   onChange={e => {setCampaign(update(campaign,
                     {design: {main_settings: {title: {$set: e.target.value}}}}))}}/>
          </div>
          <div>
            <div className="settings-item-label add-margin">{_t('title.color')}:</div>
            <ColorPicker color={settings.title_color} onChangeSettings={value => setCampaign(update(campaign,
              {design: {main_settings: {title_color: {$set: value}}}}))}/>
          </div>
          <div>
            <div className="settings-item-label add-margin">{_t('subtitle.label')}:</div>
            <input value={settings.sub_title}
                   type="text" className="node-settings-input w-input" maxLength="256"
                   onChange={e => {setCampaign(update(campaign,
                     {design: {main_settings: {sub_title: {$set: e.target.value}}}}))}}/>
          </div>
          <div>
            <div className="settings-item-label add-margin">{_t('subtitle.color')}:</div>
            <ColorPicker color={settings.sub_title_color} onChangeSettings={value => setCampaign(update(campaign,
              {design: {main_settings: {sub_title_color: {$set: value}}}}))}/>
          </div>
        </div>
      </div>

      {userCurrentPlan.removable_branding && (
        <div className="show-group-content-2 no-top-m">
          <div className="show-group-div">
            <div className="group-row al-center">
              <div className="settings-item-label">{_t('branding')}:</div>
              <Switch isOn={settings.enable_branding}
                      onClick={() => setCampaign(update(campaign,
                        {design: {main_settings: {$toggle: ['enable_branding']}}}))}/>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Design = (props) => {
  const {campaign, setCampaign, _t} = props;
  const settings = campaign.design.design_settings;
  const [pickVideoVisibility, showPickVideo] = useState(false);
  const [activeTab, setActiveTab] = useState('color');
  const {videos, getVideo} = useVideos();
  useEffect(() => {
    getVideo();
  }, []);

  const handleChangeBackgroundType = (type) => {
    setActiveTab(type);
    setCampaign(update(campaign, {design: {design_settings: {background: {type: {$set: type}}}}}));
  };
  return (
    <>
      <div className="show-group-content-2 no-top-m">
        <div className="show-group-div">
          <div className="settings-group-label">{_t('backgroundSettings.label')}:</div>
          <div className="group-content-w no-top-bord">
            <div className="w-tabs">
              <div className="w-clearfix w-tab-menu">
                <a href="#"
                   className={`choose-item min100 w-inline-block w-tab-link ${activeTab === BACKGROUND_TYPES.color ? 'w--current' : ''}`}
                   onClick={() => handleChangeBackgroundType(BACKGROUND_TYPES.color)}>
                  {_t('backgroundSettings.color')}
                </a>
                <a href="#"
                   className={`choose-item min100 w-inline-block w-tab-link ${activeTab === BACKGROUND_TYPES.gradient ? 'w--current' : ''}`}
                   onClick={() => handleChangeBackgroundType(BACKGROUND_TYPES.gradient)}>
                  {_t('backgroundSettings.gradient')}
                </a>
                <a href="#"
                   className={`choose-item min100 w-inline-block w-tab-link ${activeTab === BACKGROUND_TYPES.image ? 'w--current' : ''}`}
                   onClick={() => handleChangeBackgroundType(BACKGROUND_TYPES.image)}>
                  {_t('backgroundSettings.image')}
                </a>
                <a href="#"
                   className={`choose-item min100 w-inline-block w-tab-link ${activeTab === BACKGROUND_TYPES.video ? 'w--current' : ''}`}
                   onClick={() => handleChangeBackgroundType(BACKGROUND_TYPES.video)}>
                  {_t('backgroundSettings.video')}
                </a>
              </div>

              <div className="w-tab-content">
                <div className={`w-tab-pane ${activeTab === 'color' ? 'w--tab-active' : ''}`}>
                  <div className="show-group-content no-indent-10">
                    <div className="show-group-div">
                      <div className="group-row vertical">
                        <div className="bottom15">
                          <div className="settings-item-label add-margin">{_t('backgroundSettings.colorThemes.label')}:</div>
                          <GradientPicker colors={settings.background.color}
                                          onChangeSettings={value => setCampaign(update(campaign,
                                            {design: {design_settings: {background: {color: {$set: value}}}}}))}/>
                        </div>
                        <div className="bottom15">
                          <div className="settings-item-label add-margin">{_t('backgroundSettings.colorThemes.custom')}</div>
                          <ColorPicker color={settings.background.color[0]} onChangeSettings={value => setCampaign(update(campaign,
                            {design: {design_settings: {background: {color: {0: {$set: value}}}}}}))}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`w-tab-pane ${activeTab === 'gradient' ? 'w--tab-active' : ''}`}>
                  <div className="show-group-content no-indent-10">
                    <div className="show-group-div">
                      <div className="group-row vertical">
                        <div className="bottom15">
                          <div className="settings-item-label add-margin">{_t('backgroundSettings.gradientThemes.label')}:</div>
                          <GradientPicker colors={settings.background.color}
                                          onChangeSettings={value => setCampaign(update(campaign,
                                            {design: {design_settings: {background: {color: {$set: value}}}}}))}/>
                        </div>
                        <div className="bottom15">
                          <div className="settings-item-label add-margin">{_t('backgroundSettings.customGradientThemes.label')}</div>
                          <ColorPicker color={settings.background.color[0]} onChangeSettings={value => setCampaign(update(campaign,
                            {design: {design_settings: {background: {color: {0: {$set: [value]}}}}}}))}/>
                          <ColorPicker color={settings.background.color[1]} onChangeSettings={value => setCampaign(update(campaign,
                            {design: {design_settings: {background: {color: {1: {$set: [value]}}}}}}))}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`w-tab-pane ${activeTab === 'image' ? 'w--tab-active' : ''}`}>
                  <div className="show-group-content no-indent-10">
                    <div className="settings-item-label add-margin">{_t('backgroundSettings.image.upload.label')}:</div>
                    <div className="div-block-11">
                      <ButtonImageLoader text={_t('uploadImage')}
                                         onChange={file => setCampaign(update(campaign,
                                     {design: {design_settings: {background: {image: {$set: file.file}, image_url: {$set: ''}}}}}))}/>
                    </div>
                    <div>
                      <div className="page-subheader add-margin">
                        {_t('enterUrl')}
                      </div>
                      {_t('enterUrl.placeholder', {},
                        msg => <input type="text" value={settings.image_url}
                                      onChange={e => setCampaign(update(campaign,
                                        {design: {design_settings: {background: {image_url: {$set: e.target.value}, image: {$set: null}}}}}))}
                                      className="node-settings-input w-input" maxLength="256" placeholder={msg}/>)}
                    </div>
                  </div>
                </div>

                <div className={`w-tab-pane ${activeTab === 'video' ? 'w--tab-active' : ''}`}>
                  <div className="show-group-content no-indent-10">
                    <div className="settings-item-label add-margin">{_t('backgroundSettings.video.upload.label')}:</div>
                    <div className="div-block-11">
                      <ButtonImageLoader text={_t('uploadVideo')}
                                         type={'video'}
                                         onChange={file => setCampaign(update(campaign,
                                           {design: {design_settings: {background: {video: {$set: file.file}, image_url: {$set: ''}}}}}))}/>
                    </div>
                    <div className="div-block-11 _10">
                      <div onClick={() => showPickVideo(true)} className="blue-button facebook-btn right-margin w-button">
                        {_t('pickVideo')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="show-group-content-2 no-top-m">
        <div className="show-group-div">
          <div className="settings-group-label">{_t('messagesColors.label')}:</div>
          <div className="group-content-w">
            <div className="show-group-content no-indent-10">
              <div className="show-group-div">
                <div className="group-row vertical">
                  <div className="bottom15">
                    <div className="settings-item-label add-margin">{_t('messagesColors.bot.background')}:</div>
                    <ColorPicker color={settings.message_colors.bot_background} onChangeSettings={value =>
                      setCampaign(update(campaign, {design: {design_settings: {message_colors: {bot_background: {$set: value}}}}}))}/>
                  </div>
                  <div className="bottom15">
                    <div className="settings-item-label add-margin">{_t('messagesColors.bot.text')}:</div>
                    <ColorPicker color={settings.message_colors.bot_text} onChangeSettings={value =>
                      setCampaign(update(campaign, {design: {design_settings: {message_colors: {bot_text: {$set: value}}}}}))}/>
                  </div>
                  <div className="bottom15">
                    <div className="settings-item-label add-margin">{_t('messagesColors.user.background')}:</div>
                    <ColorPicker color={settings.message_colors.user_background} onChangeSettings={value =>
                      setCampaign(update(campaign, {design: {design_settings: {message_colors: {user_background: {$set: value}}}}}))}/>
                  </div>
                  <div className="bottom15">
                    <div className="settings-item-label add-margin">{_t('messagesColors.user.text')}:</div>
                    <ColorPicker color={settings.message_colors.user_text} onChangeSettings={value =>
                      setCampaign(update(campaign, {design: {design_settings: {message_colors: {user_text: {$set: value}}}}}))}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="show-group-content-2 no-top-m">
        <div className="show-group-div">
          <div className="settings-group-label">{_t('buttonAndInputColors.label')}:</div>
          <div className="group-content-w">
            <div className="show-group-content no-indent-10">
              <div className="show-group-div">
                <div className="group-row vertical">
                  <div className="bottom15">
                    <div className="settings-item-label add-margin">{_t('buttonAndInputColors.button.background')}:</div>
                    <ColorPicker color={settings.button_and_input_colors.button_background} onChangeSettings={value =>
                      setCampaign(update(campaign, {design: {design_settings: {button_and_input_colors: {button_background: {$set: value}}}}}))}/>
                  </div>
                  <div className="bottom15">
                    <div className="settings-item-label add-margin">{_t('buttonAndInputColors.button.text')}:</div>
                    <ColorPicker color={settings.button_and_input_colors.button_text} onChangeSettings={value =>
                      setCampaign(update(campaign, {design: {design_settings: {button_and_input_colors: {button_text: {$set: value}}}}}))}/>
                  </div>
                  <div className="bottom15">
                    <div className="settings-item-label add-margin">{_t('buttonAndInputColors.input')}:</div>
                    <ColorPicker color={settings.button_and_input_colors.input} onChangeSettings={value =>
                      setCampaign(update(campaign, {design: {design_settings: {button_and_input_colors: {input: {$set: value}}}}}))}/>
                  </div>
                  <div className="bottom15">
                    <div className="settings-item-label add-margin">{_t('buttonAndInputColors.input.text')}:</div>
                    <ColorPicker color={settings.button_and_input_colors.input_text} onChangeSettings={value =>
                      setCampaign(update(campaign, {design: {design_settings: {button_and_input_colors: {input_text: {$set: value}}}}}))}/>
                  </div>
                  <div className="bottom15">
                    <div className="settings-item-label add-margin">{_t('buttonAndInputColors.input.border')}:</div>
                    <ColorPicker color={settings.button_and_input_colors.input_border} onChangeSettings={value =>
                      setCampaign(update(campaign, {design: {design_settings: {button_and_input_colors: {input_border: {$set: value}}}}}))}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PickVideoModal isOpen={pickVideoVisibility} videos={videos}
                      onChoose={file => {
                        setCampaign(update(campaign,
                          {design: {design_settings: {background: {video: {$set: file}, image_url: {$set: ''}}}}}));
                        showPickVideo(false);
                      }}
                      onClose={() => showPickVideo(false)}/>
    </>
  );
};

const FontSettings = (props) => {
  const {campaign, setCampaign, _t} = props;
  const settings = campaign.design.font_settings;

  const fontFamilies = {
    'Circular': {value: 'Circular', label: 'Circular'},
    'Arial': {value: 'Arial', label: 'Arial'},
    'Helvetica': {value: 'Helvetica', label: 'Helvetica'},
    'Verdana': {value: 'Verdana', label: 'Verdana'},
    'Gill Sans': {value: 'Gill Sans', label: 'Gill Sans'},
    'Noto Sans': {value: 'Noto Sans', label: 'Noto Sans'},
    'Times New Roman': {value: 'Times New Roman', label: 'Times New Roman'},
    'Georgia': {value: 'Georgia', label: 'Georgia'},
    'Palatino': {value: 'Palatino', label: 'Palatino'},
    'Bookman': {value: 'Bookman', label: 'Bookman'},
    'New Century': {value: 'New Century', label: 'New Century'},
    'Andale Mono': {value: 'Andale Mono', label: 'Andale Mono'},
    'Courier New': {value: 'Courier New', label: 'Courier New'},
    'Courier': {value: 'Courier', label: 'Courier'},
    'FreeMono': {value: 'FreeMono', label: 'FreeMono'},
  };

  return (
    <>
      <div className="show-group-content-2 no-top-m">
        <div className="show-group-div">
          <div>
            <div className="settings-item-label add-margin">{_t('font.size')}:</div>
            <input value={settings.size}
                   type="text" className="node-settings-input w-input" maxLength="256"
                   onChange={e => {setCampaign(update(campaign,
                     {design: {font_settings: {size: {$set: e.target.value}}}}))}}/>
          </div>
          <div>
            <div className="settings-item-label add-margin">{_t('font.family')}:</div>
            <CustomSelect options={Object.values(fontFamilies)}
                          defaultValue={Object.values(fontFamilies)[0]}
                          selectClassName={`settings-select w-select`}
                          getOptionLabel={option => option.label}
                          onChange={family => setCampaign(update(campaign,
                            {design: {font_settings: {family: {$set: family.value}}}}))}
                          value={fontFamilies[settings.family]}/>
          </div>
        </div>
      </div>
    </>
  );
};

const PickVideoModal = (props) => {
  const {videos, isOpen, onClose, onChoose} = props;

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
      className="popup-container-2 outline-none no-max-w"
      overlayClassName="popup-w w--open"
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
      onRequestClose={onClose}
    >
      <div className="sign-header">Choose a video background</div>

      <div className="form-block-2 w-form">
        <div className="items-wrapper">
          {videos && videos.map(video => {
            return (
              <div className="bg-preview-wrap">
                <div className="hover-div">
                  <div className="blue-button facebook-btn abs-position-btn w-button" onClick={() => onChoose(video)}>Choose it</div>
                </div>
                <div data-video-urls={video.url} data-autoplay="true" data-loop="true" data-wf-ignore="true"
                     className="bg-video w-background-video w-background-video-atom">
                  <video autoplay="autoplay" loop="true" muted="muted">
                    <source src={video.url} type={video.mimetype} data-wf-ignore="true"/>
                  </video>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="abs-position">
        <div className="delete-btn" onClick={onClose}>
          <div className="close-line-1 rotate" style={{'backgroundColor': 'rgb(221, 221, 221)'}}></div>
          <div className="close-line-1" style={{'backgroundColor': 'rgb(221, 221, 221)'}}></div>
        </div>
      </div>
    </ReactModal>
  )
};

